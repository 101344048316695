import React from 'react'
import '../Styles/Footer.css'

const Footer = () => {
    return (
        <div className="footer">
            <p>Copyright Ⓒ sudo-nick since 2021. All rights reserved. </p>
        </div>
    )
}

export default Footer
