import React from 'react'
import Header from './Header'
import '../Styles/MyProjects.css'
import { useHistory } from 'react-router'
import { useEffect } from 'react'
import { useContext } from 'react'
import axios from 'axios'
import { useState } from 'react'
import { UserContext } from '../UserContext'
import upt from "../Images/up-triangle.png"
import ProjectMenu from './ProjectMenu'

const MyProjects = () => {
    const history = useHistory();
    const createNewBtnHandler = (e) => {
        e.preventDefault();
        history.push('/projects/new');
    }
    const userState = useContext(UserContext);
    console.log(userState.user.username);
    
    const [projects, setProjects] = useState([]);
    
    useEffect(() => {
        console.log('updated projects', projects, projects.length);
    },[projects]);
    
    useEffect(() => {
        axios.get(process.env.REACT_APP_SERVER_URL + `/api/${userState.user.username}/projects`).then( res => {
            if(res.data.success){
                console.log(res.data.projects);
                setProjects(res.data.projects);
            }else{
                history.push('/signin');
            }
        })
    },[])
    return (
        <>
            <div className="myprojects-container">
                <div className="myprojects-main">
                    <div className="myprojects-controls">
                        <h1>My Projects</h1>
                        <button onClick={createNewBtnHandler}>Create New</button>
                    </div>
                    {   projects.length ?
                        <div className="my-projects-list">
                            {
                                projects.map((project, index) => {
                                    return (
                                    <ProjectMenu upt={upt} setProjects={setProjects} project={project} index={index} />
                                    )})
                            }
                        </div> : <h1 style={{color : "white"}}>Empty</h1>
                    }   
                </div>
            </div>
        </>
    )
}

export default MyProjects
