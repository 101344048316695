import React from 'react'
import '../Styles/About.css'
import Footer from './Footer'
import Header from './Header'
import me from '../Images/me.png'

const About = () => {
    return (
        <>
            <div className="about-container">
                <div className="about-details">
                    <h2>Team</h2>
                    <img src={me} alt="" />
                    <h3 onClick={()=>window.open("https://github.com/sudo-nick16/")}>Nikit</h3>
                    <p>I hope you liked this platform!</p>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default About
