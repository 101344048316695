import React from 'react';
import '../Styles/LandingPage2.css'

const LandingPage2 = () => {
    return (
        <div className="main-2">
            <h2>Explore other's projects and get inspiration for your own.</h2>
        </div>
    )
}

export default LandingPage2
