import React from 'react'
import '../Styles/PageNotFound.css'
import Footer from './Footer'
import Header from './Header'

const PageNotFound = () => {
    return (
        <>
            <div className="pagenotfound-container">
                <div className="pagenotfound-main">
                    <h1>404</h1>
                    <h2>Page Not Found</h2>
                </div>
            </div>    
            <Footer />
        </>
    )
}

export default PageNotFound
