import React from 'react'
import '../Styles/Feed.css'
import Header from './Header'
import { Link } from 'react-router-dom'

const Feed = () => {
    return (
        <>
            <div className="feed-container">
                <input type="text" placeholder="Search" />
                <div className="results-container">
                    <div className="result">
                        <div className="result-image">
                            <img src="https://lh3.googleusercontent.com/kmUuMK37LLJTV_oYrIVfOSCWDzGpXvC5QR_Lb-uS6uf6OWE6NdTA5Y-5fZAVKUBwipI0B9B7esSZJBfLuqfuJwnmv60=w128-h128-e365" alt="result" />
                        </div>
                        <div className="result-info">
                            <Link className="h2" to="/project/smark" >Smark</Link>
                            <p>Smark is a good boi sdf dafs 
                                ads dsaf dsaf sadf sadf esd aadf ds dsf ea
                                dsf sddg srdgfhg dfgh dfghjk dfghj ertghyj dfgbhn dfgh dfgbn fgh 

                            </p>
                        </div>
                    </div>  
                    <div className="result">
                        <div className="result-image">
                            <img src="https://lh3.googleusercontent.com/kmUuMK37LLJTV_oYrIVfOSCWDzGpXvC5QR_Lb-uS6uf6OWE6NdTA5Y-5fZAVKUBwipI0B9B7esSZJBfLuqfuJwnmv60=w128-h128-e365" alt="result" />
                        </div>
                        <div className="result-info">
                        <Link className="h2">Smark</Link>

                            <p>Smark is a good boi sdf dafs 
                                ads dsaf dsaf sadf sadf esd aadf ds dsf ea
                                dsf sddg srdgfhg dfgh dfghjk dfghj ertghyj dfgbhn dfgh dfgbn fgh 

                            </p>
                        </div>
                    </div>  
                    <div className="result">
                        <div className="result-image">
                            <img src="https://lh3.googleusercontent.com/kmUuMK37LLJTV_oYrIVfOSCWDzGpXvC5QR_Lb-uS6uf6OWE6NdTA5Y-5fZAVKUBwipI0B9B7esSZJBfLuqfuJwnmv60=w128-h128-e365" alt="result" />
                        </div>
                        <div className="result-info">
                        <Link className="h2">Smark</Link>

                            <p>Smark is a good boi sdf dafs 
                                ads dsaf dsaf sadf sadf esd aadf ds dsf ea
                                dsf sddg srdgfhg dfgh dfghjk dfghj ertghyj dfgbhn dfgh dfgbn fgh 

                            </p>
                        </div>
                    </div>  
                </div>
                <button className="load-more">Load More</button>
            </div>
        </>
    )
}

export default Feed
