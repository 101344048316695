import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../Styles/Signin.css'
import Header from './Header';
import { useHistory } from 'react-router';
import { UserContext } from '../UserContext';
import { useContext } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { CircularProgress } from '@material-ui/core';

const SignIn = () => {
    const history = useHistory();
    const userState = useContext(UserContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    if(userState.user.auth){
        history.push(`/${userState.user.username}`);
    }

    const signInHandler = (e) => {
        e.preventDefault();
        console.log("signin")
        console.log({email,password})
        axios.post(process.env.REACT_APP_SERVER_URL + '/api/users/signin', {email,password},{
            credentials : "include",
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => {
            console.log("signin inside res")
            if(res.data.success){
                const {userId, username, image} = res.data.user;
                userState.setUser( u => ({
                    ...u, userId, username, image, auth: true
                }))
                console.log(username)
                history.push(`/${username}`);
            }
        })
    }

    return (
        <>
            <div className="signin-wrapper">
                <div className="radialContainer">
                    <form onSubmit={signInHandler} className="signin-container">
                            <label htmlFor="email">Email</label>
                            <input type="text" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />

                            <label htmlFor="password">Password</label>
                            <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} required />

                            <button type="submit">Sign In</button>
                            
                            <Link to="/">Forgot Password?</Link>
                    </form>
                </div>
            </div>
        </>
    )
}

export default SignIn
