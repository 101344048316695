import React from 'react'
import '../Styles/Project.css'
import Header from './Header'
import GitHubIcon from '@material-ui/icons/GitHub';
import ss from '../Images/ss.png'
import up from '../Images/up-arrow.png'
import Footer from './Footer';
import Carousel from 'react-material-ui-carousel';
import banner from "../Images/banner.png"
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { UserContext } from '../UserContext';
import { useContext } from 'react';
import { emphasize } from '@material-ui/core';
import { useHistory } from 'react-router';


const Project = (props) => {
    const userState = useContext(UserContext);
    const history = useHistory();

    const {username, project_name} = props.match.params;
    console.log(username, project_name)
    const [loading, setLoading] = useState(true);
    const [project, setProject] = useState({});

    useEffect(() => {
        axios.get(process.env.REACT_APP_SERVER_URL + `/api/get/project/${username}/${project_name}`).then(res => {
            if(res.data.success){
                console.log(res.data.project)
                setProject(res.data.project);
                setLoading(false);
            }
        })
    }, [])

    const handleClick = () => {
        console.log(project.upvotes.includes(userState.user.username))
        if(project.upvotes.includes(userState.user.username)){
            axios.post(process.env.REACT_APP_SERVER_URL + `/api/project/undo/upvote/${project.username}/${project._id}`,{},{
                withCredentials: true,
                credentials : "include"
            }).then(res => {
                if(res.data.success){
                    setProject(res.data.project);
                }
            })
        }else{
            axios.post(process.env.REACT_APP_SERVER_URL + `/api/project/upvote/${project.username}/${project._id}`,{},{
                withCredentials: true,
                credentials : "include"
            }).then(res => {
                if(res.data.success){
                    setProject(res.data.project);
                }
            })
        }
    }


    return (
        !loading ? 
        <>  
            <div className="project-container">
                <div className="project-main">
                    <div className="project-main-banner">
                        <div className="project-logo">
                            <img src={project.icon} alt="" />
                        </div>
                        <div className="project-info">
                            <h2>{project.realName}</h2>
                            <p className="project-credits">Offered by - <emphasize onClick={()=>history.push(`/${project.username}`)}>{project.username}</emphasize> </p>
                            <p className="project-description">{project.description}</p>
                            <div className="tagnames-container">
                                {
                                    project.tags.map((tag, index) => {
                                        return (<div className="tags" key={index}>{tag}</div>)
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <div className="project-controllers-btns">
                        { project.githubLink && <GitHubIcon className="git-icon" onClick={() => window.open(project.githubLink)} />}
                        <button className="get-btn" onClick={() => window.open(project.getlink)} >GET</button>
                        <button className="upvote-btn" onClick={handleClick}>
                            <div className="text-up" style={{color : project.upvotes.includes(userState.user.username)? "#FF8A00" : "white"}}>
                                {project.upvotes.includes(userState.user.username)? "UPVOTED" : "UPVOTE"}
                            </div>
                            <div className="up-amt">
                              {project.upvotes.length}
                            </div>
                        </button>
                    </div>

                {/* <div className="projects-screenshots">
                    <h2>Screenshots:</h2>
                    <Carousel className="carousel" navButtonsAlwaysVisible={true} navButtonsProps={{style:{
                        color: '#fff',
                        backgroundColor: 'transparent',
                        // position: 'absolute',
                        bottom: '0'
                    }}}
                    navButtonsWrapperProps={{
                        style: {
                
                            // top: 'unset'
                        }
                    }}                    
                    >
                        <img src={ss} alt="" />
                        <img src={ss} alt="" />
                        <img src={ss} alt="" />
                    </Carousel>
                </div> */}
                    <div className="project-about">
                        {/* <h2 className="abt-head">ABOUT {project.realName.toUpperCase()}</h2> */}
                        {
                            project.sections.map((section,index) => {
                                return(
                                    <div className="project-sections" key={index}>
                                        <div className="project-section">
                                            <h2>{section.head}</h2>
                                            <p>{section.content}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="tagnames-container not-full">
                        <div className="tags">Mern</div>
                        <div className="tags" >Node js</div>
                        <div className="tags" >React</div>
                        <div className="tags" >React</div>
                        <div className="tags" >React</div>
                        <div className="tags" >React</div>
                    </div>
                </div>
            </div>
            <Footer />
        </> : null
    )
}

export default Project


