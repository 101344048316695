import { Redirect, Route, useHistory } from "react-router";
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import dotenv from "dotenv";
import { UserContext } from "../UserContext";
import { CircularProgress } from "@material-ui/core";

dotenv.config();

export const AuthRoute = ({component: Component, ...rest}, ) => {
    const userState = useContext(UserContext);
    const [auth, setAuth] = useState(userState.user.auth);
    const [loading, setLoading] = useState(true);
    const history = useHistory()

    useEffect(() => {
        axios.post(process.env.REACT_APP_SERVER_URL + '/api/users/verify',{}, {
            withCredentials: true,
            Credential : "include"
        }).then(res => {
            if(res.data.success){
                userState.setUser((u) => ({...u, username : res.data.user.username, auth : true, userId : res.data.user.id, image : res.data.user.image }))
                setAuth(true);
                setLoading(false)
            }else{
                setAuth(false);
                setLoading(false)
                history.push('/home');
            }
        })
    },[])

    console.log("auth ", auth);
    return(
        // authState?
        loading ? 
        <div className="screen-center">
            <CircularProgress style={{color : "orange"}} />
        </div>  : 
        auth ? 
        <Route {...rest} render={ props => <Component {...props} /> } /> : <Redirect to={{pathname : '/signin'}} />
        )
}


