import React, {useState, useEffect, useContext} from 'react';
import '../Styles/ProfileEdit.css'
import axios from 'axios';
import dotenv from 'dotenv';
import { Snackbar } from '@material-ui/core';
import { Redirect } from 'react-router';
import { UserContext } from '../UserContext';

dotenv.config();

const ProfileEdit = () => {

    const userState = useContext(UserContext);

    const [name, setName] = useState("");
    const [headline, setHeadline] = useState("");
    const [bio, setBio] = useState("")
    const [github, setGithub] = useState("")
    const [linkedin, setLinkedin] = useState("")
    const [website, setWebsite] = useState("")
    const [img, setImg] = useState("")
    const [profileImage, setProfileImage] = useState(img)
    const [message, setMessage] = useState("")
    const [open, setOpen] = useState(false)
    const [pfpStr, setPfpStr] = useState("")

    // useEffect(() => {
    //     console.log(img)
    //     userState.setUser( user => ({...user, image : img}))
    // }, [img])
    

    useEffect(async() => {
        const res = await axios.get(process.env.REACT_APP_SERVER_URL + `/api/users/profile/edit`,{
            credentials: "include",
            withCredentials : true
        });
        console.log(res.data);
        if(res.data.status === 404){
            return (<Redirect to="/signin" />)
        }
        setName(res.data.user.name);
        setHeadline(res.data.user.headline);
        setBio(res.data.user.bio);
        setGithub(res.data.user.github);
        setLinkedin(res.data.user.linkedin);
        setWebsite(res.data.user.website);
        setImg(res.data.user.image);
        userState.setUser( user => ({...user, image : res.data.user.image}))
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        // const data = new FormData();
        // console.log(name, headline, bio, github, linkedin, website);
        // data.append('name', name.trim());
        // data.append('headline', headline.trim());
        // data.append('bio', bio.trim());
        // data.append('github', github.trim());
        // data.append('linkedin', linkedin.trim());
        // data.append('website', website.trim());
        // // data.append('profileImage', profileImage);
        // data.append('pfpStr', JSON.stringify(pfpStr));

        const data = {
            name : name.trim(),
            headline : headline.trim(),
            bio : bio.trim(),
            github : github.trim(),
            linkedin : linkedin.trim(),
            website : website.trim(),
            pfpStr : pfpStr
        }
        
        console.log(pfpStr, "prof")
        
        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/users/profile/edit`, data, {
            credentials: "include",
            withCredentials: true,
            headers : {
                'content-type' : 'application/json'
            }
        }).then(res => {
            console.log(res.data)
            setMessage(res.data.message)
            setOpen(true)
            setTimeout(()=>{
                setOpen(false)
            }, 700)
            if(res.data.image){
                userState.setUser(u => ({...u, image : res.data.image}))
            }
        })
    }
    
    return (
        <>
            <div className="profile-edit-container">
                <form onSubmit={handleSubmit} className="profile-edit-main">
                    <div className="my-details-container">
                        <h2>My details</h2>
                        <label htmlFor="name">Name</label>
                        <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Ex. Nick" required />

                        <label htmlFor="headline">Headline</label>
                        <input type="text" id="headline" value={headline} onChange={(e) => setHeadline(e.target.value)} placeholder="Ex. Full Stack Developer" />

                        <label htmlFor="Bio">Bio</label>
                        <textarea maxLength="200" id="Bio" value={bio} onChange={(e) => setBio(e.target.value)} placeholder="Ex. Coding Enthusiast | Currently working at Google." />

                        <label htmlFor="Github">GitHub</label>
                        <input type="text" id="Github" value={github} onChange={(e) => setGithub(e.target.value)} placeholder="your github username" />

                        <label htmlFor="LinkedIn">LinkedIn</label>
                        <input type="text" id="LinkedIn" value={linkedin} onChange={(e) => setLinkedin(e.target.value)}  placeholder="linkedin profile url" />

                        <label htmlFor="Website">Website</label>
                        <input type="text" id="Website" value={website} onChange={(e) => setWebsite(e.target.value)} placeholder="" />

                        <button>Save Changes</button>
                    </div>
                    <div className="image-container" >
                        <div className="image-main" >
                            <img src={img} alt="profile-image"/>
                            <label className="upload-image-btn" htmlFor="input-image">Upload</label>
                            <input type="file" id="input-image" onChange={(e) => {
                                if(e.target.files.length){
                                    setProfileImage(e.target.files[0]);
                                    setImg(URL.createObjectURL(e.target.files[0]))
                                    const reader = new FileReader();
                                    reader.readAsDataURL(e.target.files[0]);
                                    reader.onloadend = () => {
                                        setPfpStr(() => {
                                           const str = reader.result.replace(/\s+/g, "")
                                           console.log(str)
                                           return str
                                    })
                                    }
                                }
                                }} />
                        </div>
                    </div>
                </form>
            </div>
            <Snackbar
                anchorOrigin={{ vertical : "top" , horizontal : "center" }}
                open={open}
                // onClose={handleClose}
                message={message}
                key={ "top" +  "center"}
            />
        </>
    )
}

export default ProfileEdit
