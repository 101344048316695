import axios from 'axios';
import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router'
import "../Styles/ProjectMenu.css"
import { UserContext } from '../UserContext';

const ProjectMenu = ({project, index, upt, setProjects}) => {
    const userState = useContext(UserContext)
    const [show, setShow] = useState(false);
    const history = useHistory();
    const deleteProject = ({projectId, name, projectImage}) => {
        setShow(!show)
        console.log(projectId)
        axios.post(process.env.REACT_APP_SERVER_URL+'/api/projects/deleteProject/', {projectId, name, projectImage}, {
            withCredentials: true,
            credentials: "include"
        }).then(res => {
            if(res.data.success){
                userState.setSnackbar({open : true, message : "Project deleted"})
                setProjects(projects => projects.filter(project => project._id !== projectId));
            }
        })
    }
    return (
        <div className="project" key={project.name}>
            <img src={project.icon} onClick={()=>history.push(`/${project.username}/${project.name}`)} alt="" className="project-img" />
            <div className="name-menu-wrapper">
                <div className="name-icon-container">
                    <h3 className="project-name" >{project.realName}</h3>
                    <img src={upt} alt="" onClick={()=>{setShow(!show)}} className={show ? "up-icon-menu" : "up-icon-menu rotate"} />
                </div>
                <div className={show ? "project-menus-container" : "project-menus-container hidden"}>
                    <h2 onClick={()=>history.push(`/${project.username}/${project.name}/edit`)} >Edit</h2>
                    <h2 onClick = {() => deleteProject({projectId : project._id, name : project.name, projectImage : project.icon})}>Delete</h2>
                </div>
            </div>
        </div>
    )
}

export default ProjectMenu
