import React, {useEffect, useState} from 'react'
import '../Styles/Header.css'
import SearchIcon from '@material-ui/icons/Search';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import {Link, useHistory} from 'react-router-dom'
import { UserContext } from '../UserContext';
import { useContext } from 'react';
import { Snackbar } from '@material-ui/core';
import { useMemo } from 'react';
import axios from 'axios';

const Header = () => {
    const history = useHistory()
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [showlinks, setShowlinks] = useState(false);
    const [barColor, setBarColor] = useState(false);
    const userState = useContext(UserContext);
    const [showProfileOptions, setShowProfileOptions] = useState(false);
    const profileMemo = useMemo(() => ({showProfileOptions, setShowProfileOptions}), [showProfileOptions, setShowProfileOptions]);    

    console.log(userState, "userstate");

    useEffect(()=>{
        setNav(window.location.pathname)
    },[])

    const logout = () => {
        axios.post(process.env.REACT_APP_SERVER_URL + "/api/users/logout", {}, {
            withCredentials: true,
            credentials : "include",
            headers: {}
        }).then(res => {
            setMessage(res.data.message);
            setOpen(true);
            setTimeout(() => {
                setOpen(false);
            },700)
        })
        userState.setUser( u => (
            {...u, auth:false, image : null, userId : null, username : null}
        )) 
        history.push("/")
    }
    const [nav, setNav] = useState(window.location.pathname);
    // setNav(window.location.pathname)

    console.log(window.location.pathname)
    return (
        <div className="navbar-container" >
            <div className="showoff-icon">
                <Link className="logo" to="/">S</Link>
            </div>
            <div className={showlinks ? "middleContainer" : "middleContainer hidden1"}>
                <Link to="/explore" className="nav-text" onClick={()=>{setNav("/explore");setShowlinks(false)}} style={{"color" : nav==="/explore"? "#FF8A00" : "white"}} >Explore</Link>
                {/* , "font-weight" : nav=="home"? "600" : "400" */}
                <Link to="/" className="nav-text" onClick={()=>{setNav("/");;setShowlinks(false)}} style={{"color" :nav==="/"? "#FF8A00" : "white"}} >Home</Link>
                <Link to="/about" className="nav-text" onClick={()=>{setNav("/about");;setShowlinks(false)}} style={{"color" : nav==="/about"? "#FF8A00" : "white"}} >About</Link>
            </div>
            <div className="hamburger-container">
                <MenuRoundedIcon className="hamburger-icon"
                    style={{color : !barColor? "white": "#FF8A00"}} onMouseOver={() => setBarColor(true)} onMouseOut={() => setBarColor(false)}
                    onClick={() => setShowlinks(!showlinks)} />
            </div>
            {
                userState.user.auth?
                <>
                    <div className="rightSide" >
                        <img src={userState.user.image} alt="Fuck" onMouseClick={() => setShowProfileOptions(true)} onMouseOver={() => setShowProfileOptions(true)} onMouseOut={() => setShowProfileOptions(false)} />
                        <div className={ showProfileOptions? "user-options-container" : "user-options-container hidden2"}  onMouseOver={() => setShowProfileOptions(true)} onMouseOut={() => setShowProfileOptions(false)}  >
                            <Link className="user-options" to={`/${userState.user.username}`} >My Profile</Link>
                            <Link className="user-options" to="/profile/edit" >Edit Profile</Link>
                            <Link className="user-options" to="/my/projects" >My Projects</Link>
                            <button className="user-options" onClick={()=>{logout()}}>Logout</button>
                        </div>
                    </div>
                                                
                </>
                    : 
                <>
                    <div className="rightSide" >
                        {/* <Link to="/about">About</Link> */}
                        <Link className="signin" to="/signin">Sign In</Link>
                    </div>
                    
                </>    
            }
        <Snackbar
            anchorOrigin={{ vertical : "top" , horizontal : "center" }}
            open={open}
            // onClose={handleClose}
            message={message}
            key={ "top" +  "center"}
        />
    </div>
    )
}

export default Header
