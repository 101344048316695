import React from 'react'
import Header from './Header'
import Footer from './Footer'
import LandingPage1 from './LandingPage1'
import LandingPage2 from './LandingPage2'

const LandingPage = () => {
    return (
        <>  
            <LandingPage1 />
            <LandingPage2 />
            <Footer />
        </>
    )
}

export default LandingPage
