import React, { useEffect, useState } from 'react'
import '../Styles/ProjectNew.css'
import temp from '../Images/defaultIcon.png'
import {produce} from 'immer'
import DeleteIcon from '@material-ui/icons/Delete';
import Footer from './Footer'
import axios from 'axios'
import { useContext } from 'react'
import { UserContext } from '../UserContext'
import { useHistory } from 'react-router'
import CloseIcon from '@material-ui/icons/Close';

const ProjectNew = () => {
    const userState = useContext(UserContext);
    const history = useHistory();

    const [getUrl, setGetUrl] = useState('');
    const [git, setGit] = useState('');
    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const [img, setImg] = useState(temp);
    const [projectImage, setProjectImage] = useState(null);
    const [sections, setSections] = useState([]);
    const [tags, setTags] = useState([]);
    const [newTag, setNewTag] = useState([]);

    // useEffect(()=> {
    //     if(tags.length > 5){
    //         userState.setSnackbar({open : true, message : 'You can only have 5 tags'});
    //     }
    // },[tags])


    const submitHandler = (e) => {
        e.preventDefault();
        const data = {
            realName: name,
            name: ((name.toLowerCase()).trim()).split(' ').join('-'),
            description: desc.trim(),
            getlink: getUrl.trim(),
            githubLink: git.trim(),
            sections: sections,
            icon: projectImage,
            tags : tags
        }

        console.log(process.env.REACT_APP_SERVER_URL)

        axios.post(process.env.REACT_APP_SERVER_URL + '/api/new/project', data, {
            withCredentials : true,
            credentials : 'include',
            headers: {
                'content-type' : 'application/json'
            }
        }).then(res => {
            console.log(res.data)
            userState.setSnackbar({open : true, message : res.data.message})
            if(res.data.success){
                history.push(`/${userState.user.username}/${((name.toLowerCase()).trim()).split(' ').join('-')}`)
            }
        })
    }
    return (
        <>
         <form onSubmit={submitHandler} className="project-new-container">
            <div className="project-new-main">
                <div className="project-new-link">
                    <h3>Link</h3>
                    <input type="text" value={getUrl} onChange={(e)=>setGetUrl(e.target.value)} placeholder="URL of the project (eg. https://showoff.com/)" />
                    <h3>GitHub link if your project is open sourced : </h3>
                    <input type="text" value={git} onChange={(e) => setGit(e.target.value)} placeholder="Github repository URL (optional)" />
                </div>
                <div className="project-new-details">
                    <div className="project-new-intro">
                        <h3>Name</h3>
                        <input type="text" value={name} onChange={(e)=>{setName(e.target.value)}} placeholder="Name of the project." required />
                        <label htmlFor="project-new-icon">
                            Upload Icon
                        </label>
                        <input type="file" id="project-new-icon" name="project-new-icon" onChange={(e) => {
                            // setProjectImage(e.target.files[0]);
                            if(e.target.files.length) {
                                setImg(URL.createObjectURL(e.target.files[0]))
                                const reader = new FileReader();
                                reader.readAsDataURL(e.target.files[0]);
                                reader.onloadend = () => {
                                    console.log(reader.result)
                                    setProjectImage(reader.result)
                                }
                            }
                        }} required />
                    </div>
                    <div className="project-new-image">
                        <img src={img} alt="" />
                    </div>
                </div>

                <div className="project-new-desc">
                    <div className="project-new-brief-desc">
                        <h3>Description</h3>
                        <textarea value={desc} onChange={(e)=>setDesc(e.target.value)} maxLength="300" name="" id="" required ></textarea>
                    </div>   
                    <div className="sections">
                        <h3>Sections</h3>
                        {   
                            sections.map((section, index) => {
                                return (
                                
                                <div className="section" key={index}>
                                    <input type="text" placeholder="Heading" value={section.head} onChange = {(e) => {
                                        const currHead = e.target.value;
                                        setSections((currSections) => {
                                            return produce(currSections, (draft) => {
                                                draft[index].head = currHead;
                                            })
                                        });
                                    }} />
                                    <textarea placeholder="Description" value={section.content} onChange={(e)=>{
                                        const currContent = e.target.value;
                                        setSections((currSections) => {
                                            return produce(currSections, (draft) => {
                                                draft[index].content = currContent;
                                            })
                                        });
                                    }} maxLength="500"  />
                                    <DeleteIcon className="delete-icon" onClick={(e) => {
                                        e.preventDefault();
                                        setSections((currSections) => {
                                            return produce(currSections, (draft) => {
                                                draft.splice(index, 1);
                                            })
                                        });
                                    }} />
                                </div>
                            )})
                        }

                        <button onClick={(e) => {
                            e.preventDefault();
                            setSections(currSections => [...currSections, {
                                head : "",
                                content : ""
                            }])
                        }} >Add a section</button>
                    </div>
                </div>
                <div className="tag-container">
                    <h3>Tags</h3>
                    { tags.length ? 
                    <div className="tags">
                        {
                            tags.map((tag, index) => {
                                return (
                                    <div className="tag" key={index}>
                                        <span>{tag}</span>
                                        <CloseIcon className="delete-icon" onClick={(e) => {
                                            e.preventDefault();
                                            setTags((currTags) => {
                                                return produce(currTags, (draft) => {
                                                    draft.splice(index, 1);
                                                })
                                            });
                                        }} />
                                    </div>
                                )
                            })
                        }
                    </div>
                    :
                    <div className="tags-placeholder">
                        <span>Add tags to your project.</span>
                    </div>
                    }
                    <input type="text" placeholder="Add a tag" value={newTag} onChange={(e) => {
                        setNewTag(e.target.value);
                    }} />
                    <button onClick={(e) => {
                        e.preventDefault();
                        setTags((currTags) => {
                            if(!newTag.trim()){
                                return [...currTags];
                            }
                            if(currTags.length > 4){
                                userState.setSnackbar({open : true, message : 'You can only have 5 tags'});
                                return [...currTags]
                            }else{
                                return [...currTags, newTag]
                            }
                        });
                        setNewTag("");
                    }} >Add Tag</button>
                </div>
                <button type="submit" className="submit-btn">Submit</button>
            </div>
         </form>
         <Footer />
        </>
    )
    
}

export default ProjectNew
