import React from 'react'
import '../Styles/LandingPage1.css'
import { useHistory } from 'react-router-dom'

const LandingPage1 = () => {
    const history = useHistory()
    const redirectSignUp = () => {
        console.log('redirecting to sign up')
        history.push('/signup')
    }
    return (
        <div className="main">
            <div className="leftSide">
                <h1 className="main-text">
                    Now <p>showoff</p> your projects like never before.
                </h1>
                <button onClick={redirectSignUp}>Sign Up</button>
            </div>
            <div className="rightSide">
                
            </div>
        </div>
    )
}

export default LandingPage1
