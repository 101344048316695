import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import '../Styles/Signin.css'
import Header from './Header';
import { useHistory } from 'react-router';
import { Snackbar} from '@material-ui/core';
import dotenv from 'dotenv';
import { useContext } from 'react';
import { UserContext } from '../UserContext';
import axios from 'axios';

dotenv.config();

const SignUp = () => {
    const history = useHistory();

    const userState = useContext(UserContext);

    if(userState.user.auth){
        history.push(`/${userState.user.username}`);
    }

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    console.log(process.env.REACT_APP_SERVER_URL)
    // || `http://localhost:3002/api/users/signup`
    
    const signUpHandler = (e) => {
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/users/signup` ,{
                username,
                email,
                password
            },{
            credentials: "include",
            withCredentials: true,
            headers : {
                'Content-Type' : 'application/json',
            }
        }).then(res => {
            if(res.data.success){
                userState.setUser( user => ({...user, auth : true, username : username}))
                history.push("/profile/edit");
            }else{
                userState.setSnackbar({open: true, message: res.data.message})
            }
            // setUserId(res.userId);
        })
    }
    return (
        <>
            <div className="signin-wrapper">
                <div className="radialContainer">
                    <form onSubmit={signUpHandler} className="signin-container signup-container">
                            <label htmlFor="username">Username</label>
                            <input type="text" name="username" value={username} onChange={(e) => setUsername((e.target.value).trim())} required />

                            <label htmlFor="email">Email</label>
                            <input type="text" name="email" value={email} required onChange={(e) => setEmail((e.target.value).trim())} required />

                            <label htmlFor="password">Password</label>
                            <input type="password" name="password" onChange={(e) => setPassword((e.target.value).trim())} required />

                            <button>Sign Up</button>
                            {/* <input type="submit" onClick={signUpHandler} value="Sign Up" /> */}
                            
                            <Link to="/signin">Already have an account?</Link>
                    </form>
                </div>
            </div>
        </>
    )
}

export default SignUp
