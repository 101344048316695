import React,{useState, useEffect, useContext} from 'react'
import '../Styles/Profile.css'
import Footer from './Footer'
import Header from './Header'
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import LanguageIcon from '@material-ui/icons/Language';
import upArrow from '../Images/up-arrow.png'
import axios from 'axios';
import { useHistory } from 'react-router';
import banner from '../Images/banner.png'
import { UserContext } from '../UserContext';

const Profile = (props) => {
    const [user, setUser] = useState({
        user : {},
        projects : []
    });
    const userState = useContext(UserContext)

    console.log(props.match.params);
    
    const history = useHistory();
    const username  = props.match.params.profile_username;
    console.log(username);


    useEffect(() => {  
        axios.get(process.env.REACT_APP_SERVER_URL + `/api/users/profile/${username}`).then( res => {
            console.log(res.data);
            console.log(res.data.user, res.data.success);
            if(res.data.success){
                console.log(res.data.user);
                setUser( u => ({...u, user : {...res.data.user, image : res.data.user.image}}));
                // userState.setUser(u => ({...u, image : res.data.user.image }));
            }else{
                history.push('/');
            }
        })
        
        axios.get(process.env.REACT_APP_SERVER_URL + `/api/${username}/projects`).then( res => {
            if(res.data.success){
                setUser( u => ({...u, projects : res.data.projects}));
            }
        })
        
    }, [])
    
    console.log(user);

    return (
        <> 
            <div className="profile-main" >
                {/* <img src={banner} alt="" className="banner" /> */}
                <div className="profile-banner-div">
                    <div className="profile-image-div">
                        <img src={user.user.image} alt="" />
                    </div>
                    <div className="profile-details-div">
                        <h1 className="name">{user.user.name}</h1>
                        <h2 className="headline">{user.user.headline}</h2>
                        <p className="bio">{user.user.bio}</p>
                        <div className="profile-links">
                            {user.user.github && <GitHubIcon className="profile-link-icon github" onClick={()=> window.open("https://github.com/"+ user.user.github, '_blank')}  />}
                            {/* <TwitterIcon className="profile-link-icon" onClick={()=> window.open(user.twitter)}  /> */}
                            {user.user.linkedin && <LinkedInIcon className="profile-link-icon linkedin" onClick={()=> window.open('//'+ user.user.linkedin, '_blank')} onmouseover = 'this.style.backgroundColor = "blue"' />}
                            {user.user.website && <LanguageIcon className="profile-link-icon website" onClick={()=> window.open('//'+ user.user.website, '_blank')} />}
                        </div>
                    </div>
                </div>
                <div className="upvotes-div">
                    <div className="upvotes-info">
                        <h1>UPVOTES</h1>
                        {
                            console.log(user.user.upvotes," upvotes type ")
                        }
                        <h1 className="no-of-upvotes">{user.user.upvotes}</h1>
                    </div>
                    <div className="upvotes-icon">
                        <img src={upArrow} alt="" />
                    </div>
                </div>
            {
                user.projects.length ? 
                <div className="all-projects-div">
                    <h1>PROJECTS</h1>
                    <div className="projects">
                    {   user.projects.map((project, index) =>{
                            console.log(project, "<--- project");
                            return (
                                <div className="project" key={index} onClick={()=> history.push(`/${project.username}/${project.name}`)} >
                                    <img src={project.icon} alt="" className="project-img" />
                                    <h3 className="project-name">{project.realName}</h3>
                                </div>
                            )
                        })
                    }                               
                    </div>
                </div>
                :
                null
            }
            </div>
            <Footer />
            </>
    )
}

export default Profile
