import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LandingPage from './Components/LandingPage';
import SignIn from './Components/SignIn';
import SignUp from './Components/SignUp';
import Profile from './Components/Profile';
import Feed from './Components/Feed';
import Project from './Components/Project';
import About from './Components/About';
import {AuthRoute} from './Routes/AuthRoute';
import ProfileEdit from './Components/ProfileEdit';
import PageNotFound from './Components/PageNotFound';
import dotenv from 'dotenv';
import MyProjects from './Components/MyProjects';
import ProjectNew from './Components/ProjectNew'
import {UserContext} from './UserContext';
import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import Logout from './Functions/Logout';
import { CircularProgress, Snackbar } from '@material-ui/core';
import ProjectEdit from './Components/ProjectEdit';
import Header from './Components/Header';
import Explore from './Components/Explore';

dotenv.config();

function App() {
  const [user, setUser] = useState({
    auth : false,
    userId : null,
    username : null,
    image : null,
    imageQuery : Date.now(),
  });

  const [snackbar, setSnackbar] = useState({
    open : false,
    message : '',
  })
  
  const providerValue = useMemo(() => ({user, setUser, setSnackbar}), [user, setUser, snackbar, setSnackbar]);
  const [loading, setLoading] = useState(true);

  useEffect(async ()  => {
    try{
      await axios.post(process.env.REACT_APP_SERVER_URL + '/api/users/verify',{}, {
        withCredentials: true,
        credentials : "include"
      }).then(res => {
        console.log("authorising")
        if(res.data.success){
            console.log(res.data.user.username);
            setUser( u => ({...u, username : res.data.user.username, auth : true, userId : res.data.user.id, image : res.data.user.image }));
            setLoading(false);
            console.log(user, loading);
            console.log(user.username, "user");
            // <Redirect to={{pathname : `/${user.username}`}} />
            console.log("hmm")
          }else{
          }
          setLoading(false);
      })
    }catch(err){
      setLoading(false);
    }
  },[])
  
  return (
    loading? 
    <div className="screen-center">
            <CircularProgress style={{color : "orange"}} />
    </div> :
    <UserContext.Provider value={providerValue}>
    <Router>
    <div className="App">
      <Snackbar 
        anchorOrigin={{ vertical : "top" , horizontal : "center" }}
        key={ "top" +  "center"}
        open={snackbar.open}
        autoHideDuration={800}
        onClose={() => setSnackbar(s => ({...s, open : false}))}
        message={snackbar.message}
      />
      <Header show />
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/signin" component={SignIn} />
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/feed" component={Feed} />
        <AuthRoute exact path="/projects/new" component={ProjectNew} />
        <AuthRoute exact path="/my/projects/" component={MyProjects} />
        <Route path="/about" component={About} />
        <Route exact path="/explore" component={Explore} />
        <AuthRoute path="/profile/edit" auth = {user.auth} component={ProfileEdit} elsePath="/profile/edit" />
        <Route exact path="/:profile_username" component={Profile} />
        <AuthRoute path="/:username/:project_name/edit" component={ProjectEdit} />
        <Route path="/:username/:project_name" component={Project} />
        <Route path="/pageNotFound" component={PageNotFound} />
      </Switch>
    </div>
    </Router>
    </UserContext.Provider>
  );
}

export default App;
